import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#181019',
            dark: '#0f0a0f',
            light: '#2e2532',
        },
        secondary: {
            main: '#ff4081',
            dark: '#f50057',
            light: '#ff80ab',
        },
    },
    typography: {
        fontFamily: "Jaro, sans-serif", // Sostituisci con il font che desideri usare
    },
});

export default defaultTheme;