import React, { useState, Suspense, lazy, useEffect } from 'react';
import './App.css';
import { Box, useTheme } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import { CustomParticles } from './theme/Particles';
import Loader from './components/Loader/Loader';

const Home = lazy(() => import('./pages/Home'));
const Roadmap = lazy(() => import('./pages/Roadmap'));
const Carosel = lazy(() => import('./pages/Carosel'));
const MemeGenerator = lazy(() => import('./pages/MemeGenerator'));
const PrevGen = lazy(() => import('./components/Generator/PrevGen'));
const Starter = lazy(() => import('./components/Home/Starter'));
const HTB = lazy(() => import('./components/HTB/HowToBuy'));
const AppPreviewPage3D = lazy(() => import('./components/AppLoader/AppLoader'));

function WrappedHome() {
  const theme = useTheme();

  return (
    <>
      <Suspense fallback={<Loader/>}>
        <Starter />
      </Suspense>
      <Box className="App" sx={{ backgroundColor: theme.palette.primary.dark }}>
        <Box id="home" className="container">
          <Suspense fallback={<Loader/>}>
            <Home />
          </Suspense>
        </Box>
        <Box className="container">
          <Suspense fallback={<Loader/>}>
            <HTB />
          </Suspense>
        </Box>
        <Box id="roadmap" className="container">
          <Suspense fallback={<Loader/>}>
            <Roadmap />
          </Suspense>
        </Box>
        <Box id="gen" className="container">
          <Suspense fallback={<Loader/>}>
            <PrevGen />
          </Suspense>
        </Box>
        <Box id="mint" className="container">
          <Suspense fallback={<Loader/>}>
            <Carosel />
          </Suspense>
        </Box>
      </Box>
    </>
  );
}

function WrappedApp() {
  const theme = useTheme();
  const [showNavbar, setShowNavbar] = useState(true);
  const location = useLocation();

  useEffect(() => {
    location.pathname === '/main' && setShowNavbar(false);
  }, [location]);

  return (
    <Box className="App-routes" sx={{ backgroundColor: theme.palette.primary.dark }}>
        <Box className="container">
          <CustomParticles />
          <Box sx={{ backgroundColor: theme.palette.primary.dark }}>
            {showNavbar && (
            <Navbar />
            )}
          </Box>
          <Routes>
            <Route path="/" element={
              <Suspense fallback={<Loader/>}>
                <AppPreviewPage3D  />
              </Suspense>
            } />
            <Route path="/main" element={
              <Suspense fallback={<Loader/>}>
                <WrappedHome />
              </Suspense>
            } />
            <Route path="/generator" element={
              <Suspense fallback={<Loader/>}>
                <MemeGenerator />
              </Suspense>
            } />
          </Routes>
        </Box>
    </Box>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();

  return location.pathname === '/' ? (
    <Suspense fallback={<Loader />}>
      <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
        <div style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
          <AppPreviewPage3D />
        </div>
      </div>
    </Suspense>
  ) : (
    <WrappedApp />
  );
}

export default App;
