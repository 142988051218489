import React, { useState } from 'react';
import { AppBar, Toolbar, Button, useTheme, Box, IconButton, Menu, MenuItem, Snackbar, Alert, Paper, useMediaQuery } from '@mui/material';
import { styled, width } from '@mui/system';
import { MonetizationOnSharp, Telegram, Twitter, Menu as MenuIcon } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';

const NavbarContainer = styled(Box)(({ theme }) => ({
    backdropFilter: 'blur(30px)',
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: '25px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '99%',
}));

const NavbarItem = styled(Button)(({ theme }) => ({
    gap: theme.spacing(1),
    transition: 'transform 0.2s',
    '&:hover': {
        transform: 'scale(1.1)',
    },
}));

const Navbar: React.FC = () => {
    const theme = useTheme();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const isMobile = window.innerWidth <= theme.breakpoints.values.sm;

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleConnectClick = () => {
        setSnackbarOpen(true);
        scrollToSection('connect');
    };

    const scrollToSection = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const renderNavbarItem = (label: string, sectionId: string) => {
        if (location.pathname === '/') {
            return (
                <NavbarItem color="secondary" sx={{ textShadow: '2px 2px 2px rgba(255, 255, 255, 0.3)' }} onClick={() => scrollToSection(sectionId)}>
                    {label}
                </NavbarItem>
            );
        } else {
            if (sectionId === 'home') {
                return (
                    <Link to={`/`}>
                        <NavbarItem color="secondary" sx={{ textShadow: '2px 2px 2px rgba(255, 255, 255, 0.3)' }}>
                            {label}
                        </NavbarItem>
                    </Link>
                );
            }
            return (
                <Link to={`/${sectionId}`}>
                    <NavbarItem color="secondary" sx={{ textShadow: '2px 2px 2px rgba(255, 255, 255, 0.3)' }}>
                        {label}
                    </NavbarItem>
                </Link>
            );
        }
    };


    return (
        <Box sx={{zIndex: 9999, alignItems: 'center', padding: 1}}>
            <NavbarContainer position={isMobile ? "fixed" : "fixed"} style={{zIndex: 9999,paddingTop: isMobile ? 10 : 2, marginLeft: -1,  width: isMobile ? '96%' : '99%'}}>
                <Box sx={{ backdropFilter: 'blur(10px)', borderRadius: '25px', width: isMobile ? '75%' : '98%' }}>
                    <Toolbar>
                        {isMobile ? (
                            <>
                                <IconButton edge="start" color="secondary" aria-label="menu" onClick={handleMenuOpen}>
                                    <MenuIcon />
                                </IconButton>
                                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                                    <MenuItem onClick={handleMenuClose}>{renderNavbarItem('Home', 'home')}</MenuItem>
                                    <MenuItem onClick={handleMenuClose}>
                                        <Link to="/generator">
                                            <NavbarItem color="secondary" sx={{ textShadow: '2px 2px 2px rgba(255, 255, 255, 0.3)' }}>Generator</NavbarItem>
                                        </Link>
                                    </MenuItem>
                                </Menu>
                            </>
                        ) : (
                            <>
                                {renderNavbarItem('Home', 'home')}
                                {location.pathname === '/' ? renderNavbarItem('Roadmap', 'roadmap') : null}
                                {location.pathname === '/' ? renderNavbarItem('Mint', 'mint') : null}
                                <Link to="/generator">
                                    <NavbarItem color="secondary" sx={{ textShadow: '2px 2px 2px rgba(255, 255, 255, 0.3)' }}>Generator</NavbarItem>
                                </Link>
                            </>
                        )}
                    </Toolbar>
                </Box>
                <Box sx={{ width: '100%', display: 'flex', mr: 2 }} justifyContent={'flex-end'}>
                    {!isMobile && (
                        <Box sx={{ gap: 2, mr: 2 }} color="secondary">
                            <IconButton color="secondary" onClick={() => window.open('https://x.com/OOBEonSol', '_blank')}><Twitter /></IconButton>
                            <IconButton color="secondary" onClick={() => window.open('https://t.me/oobesol', '_blank')}><Telegram /></IconButton>
                            <IconButton color="secondary" onClick={() => window.open('https://pump.fun/coin/8243mJtEQZSEYh5DBmvHSwrN8tmcYkAuG67CgoT2pump', '_blank')}><MonetizationOnSharp /></IconButton>
                        </Box>
                    )}
                    <Button variant="contained" color='secondary' size='small' sx={{ textShadow: '2px 2px 2px rgba(255, 255, 255, 0.3)' }} onClick={handleConnectClick}>{isMobile ? 'Connect' : 'Connect Wallet'}</Button>
                </Box>
            </NavbarContainer>
            <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="info" sx={{ width: '100%' }}>
                    Connect Wallet will be ready really soon! We devvin' :)
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Navbar;
