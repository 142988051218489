import React from 'react';
import { motion } from 'framer-motion';
import { CircularProgress, Box } from '@mui/material';

const Loader: React.FC = () => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            width="100vw"
            position="fixed"
            top={0}
            left={0}
            bgcolor="rgba(0, 0, 0, 0.9)"
        >
            <motion.div
                animate={{ rotate: 360 }}
                transition={{ repeat: Infinity, duration: 1, ease: "linear" }}
            >
                <CircularProgress sx={{width: '30%', height: '30%'}} />
            </motion.div>
        </Box>
    );
};

export default Loader;